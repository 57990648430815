<template>
  <div class="paper-admin">
    <div class="paper-admin-header">
      <el-input placeholder="请输入试卷名称" style="width: 500px;" v-model="searchValue"><el-button type="primary" @click="searchBtn" slot="append" icon="el-icon-search"></el-button></el-input>
      <el-button type="primary" @click="toAdd"><i class="el-icon-plus" style="font-size: 14px;margin-right: 4px"></i>添加试卷</el-button>
    </div>
    <el-table class="customTable" :data="paperList" style="width: 100%; margin-top: 20px; flex: 1;" height="1%" size="medium"
              :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
              :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
      <el-table-column prop="name" align="left" label="试卷名称"></el-table-column>
      <el-table-column prop="level_name" align="left" label="试卷等级"></el-table-column>
      <el-table-column prop="total_score" align="center" label="分值"></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-link type="success" :underline="false" @click="editPaper(scope.row.theory_test_paper_id)">编辑</el-link>
          <el-link type="danger" :underline="false" @click="deletePaper(scope.row.theory_test_paper_id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pager-center" style="margin: 20px 0 30px;text-align: center"
                   :current-page="paperPages.currentPageNum"
                   :page-size="paperPages.eachPageNum"
                   :total="paperPages.total"
                   layout="prev, pager, next, jumper"
                   @current-change="paperCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import { theoryTestPaperGetList, theoryTestPaperDel } from '@/utils/apis'
export default {
  name: "Questions",
  data(){
    return {
      searchValue:'',
      paperList:[],
      paperPages:{
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
    }
  },
  mounted() {
    this.getPaperList()
  },
  methods:{
    getPaperList(){
      let params = {
        paging:1,
        page:this.paperPages.currentPageNum,
        pageSize:this.paperPages.eachPageNum
      }
      if(this.searchValue){
        params.like = this.searchValue
      }
      theoryTestPaperGetList(params).then((res)=>{
        this.paperList = res.data.list;
        this.paperPages.total = res.data.total;
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    searchBtn(){
      this.getPaperList();
    },
    editPaper(id){
      this.$router.push({
        path:'/theory/questions/add',
        query: {
          id:id
        }
      })
    },
    deletePaper(id){
      this.$confirm(`此操作将永久删除该试卷，是否继续`, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass:'green-message-box',
        type:'warning'
      }).then(()=>{
        let params = {
          theory_test_paper_id:id
        }
        theoryTestPaperDel(params).then((res)=>{
          this.$message({
            message:res.msg,
            type:'success',
            duration:1000,
            onClose:()=>{
              this.getPaperList()
            }
          })
        }).catch((err)=>{
          console.log('err', err)
        })
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    paperCurrentChange(val){
      this.paperPages.currentPageNum = val;
      this.getPaperList();
    },
    toAdd(){
      this.$router.push('/theory/questions/add')
    }
  }
}
</script>

<style scoped lang="scss">
.paper-admin{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .paper-admin-header{
    display: flex;
    justify-content: space-between;
    ::v-deep .el-input{
      .el-input-group__append{
        padding-right: 10px;
        box-sizing: border-box;
        height: 40px;
        //background: #2DC079;
        color: #fff;
        font-size: 16px;
        .el-button{
          height: 40px;
          border-radius: 0 4px 4px 0;
          padding: 0 8px;
          background: #1AB163;
          &:hover{
            background: #2DC079;
          }
        }
      }
    }
  }
}
</style>